/*
 * @Author: your name
 * @Date: 2021-05-10 15:41:13
 * @LastEditTime: 2021-08-12 21:29:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /address-library/src/utils/axios.js
 */
import axios from 'axios'
const service = axios.create({
    timeout: 10000,
    // withCredentials: true
});
service.defaults.headers = {
  'Content-type': 'application/json',
  'Cache-Control': 'no-cache'
}
service.interceptors.request.use(
  config => {
      return config
  }, error => {
      return Promise.reject(error)
  })
service.interceptors.response.use(
  response => {
      return Promise.resolve(response.data)
  }, error => {
      console.log(error)
      if (error.response && error.response.status) {
          // Message({
          //     message: `服务器错误！错误代码：${error.response.status || ''}`,
          //     type: 'error'
          // })
          return Promise.reject(error.response);
      }
  })

export default service