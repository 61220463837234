<!--
 * @Author: your name
 * @Date: 2021-08-10 16:19:24
 * @LastEditTime: 2021-08-16 19:52:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /beta-user-center/src/views/demoh5/index.vue
-->
<template>
  <div class="scan">
    <el-form :inline="true" @submit.native.prevent="onSubmit" class="demo-form-inline">
      <el-form-item label="">
        <el-input
          class="demo-form-input"
          v-model="value"
          placeholder="订单号/单件码"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="">
        <el-input class="demo-form-input"  v-model="singlePieceCode" @keyup.enter.native="scanGoodsSinglePieceCode" placeholder="单件码"></el-input>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary"  @click="onSubmit">搜索</el-button>
        <el-button type="primary" @click="thermalPrintEvent"
          >打印热敏纸</el-button
        >
      </el-form-item>
    </el-form>
    <div class="box" ref="box" v-if="showQueryResult != null">
      <span class="scan-center" style="color:red;font-size:16px;">{{
        showQueryResult
      }}</span>
    </div>
    <div
      class="box"
      ref="box"
      v-if="showQueryResult == null && orderInfo != null"
    >
      <div class="scan-center">
        <el-card v-if="orderInfo.customerName" class="box-card">
          <div slot="header" class="clearfix">
            <span>订单信息</span>
          </div>
          <div v-if="orderInfo.orderNo" class="text item">
            <h3>{{ orderInfo.orderNo }}</h3>
          </div>
          <div v-if="orderInfo.customerName" class="text item">
            <span>客户名称:</span>
            {{ orderInfo.customerName }}
          </div>
          <div v-if="orderInfo.modelItemNumber" class="text item">
            <span>款号: </span>
            {{ orderInfo.modelItemNumber }}
          </div>
          <div v-if="orderInfo.createName" class="text item">
            <span>创建人: </span>
            {{ orderInfo.createName }}
          </div>
        </el-card>

        <el-card
          v-if="orderInfo.goodsInfo.length > 0"
          class="box-card card-left"
        >
          <div slot="header" class="clearfix">
            <span>商品信息</span>
          </div>
          <div
            v-for="(item, index) in orderInfo.goodsInfo"
            :key="index"
            class="text item card-item-left"
          >
            <span style="width: 50px">{{ item.productSize }}</span>
            <span
              ><b
                :style="
                  item.scannedGoodsItemNum < item.goodsItemTotalNum
                    ? 'color: red;'
                    : ''
                "
                >{{ item.scannedGoodsItemNum }}</b
              >{{ '/' + item.goodsItemTotalNum }}</span
            >
            <span style="width:50px;text-align:right">{{
              item.goodsUnit
            }}</span>
          </div>
        </el-card>
      </div>

      <div class="scan-bottom">
        <table class="scan-info" border="1" cellspacing="0">
          <tr height="40px">
            <td colspan="12" align="center">贝塔工艺单</td>
          </tr>

          <!-- <tr height="40px" align="center" class="scan-tr">
            <td rowspan="5">
              <img v-if="orderInfo.betaTechnologyBill.frontImage" :src="orderInfo.betaTechnologyBill.frontImage" alt="商品图">
            </td>
            <td>贝塔款号</td>
            <td>{{orderInfo.betaTechnologyBill.modeItemNumber}}</td>
            <td>设计师</td>
            <td>{{orderInfo.betaTechnologyBill.designer}}</td>
          </tr>
          <tr height="40px" align="center" class="scan-tr">
            <td>客户名称</td>
            <td>{{orderInfo.betaTechnologyBill.customerName}}</td>
            <td>设计日期</td>
            <td>{{orderInfo.betaTechnologyBill.designDate}}</td>
          </tr> -->
          <!-- <tr height="40px" align="center" class="scan-tr">
            <td>纸样师</td>
            <td></td>
            <td>{{orderInfo.betaTechnologyBill.patternMaker}}</td>
            <td>洗水标模版</td>
            <td>{{orderInfo.betaTechnologyBill.washWaterMarkTemplate}}</td>
          </tr>
          <tr height="40px" align="center" class="scan-tr">
            <td>尺寸模版</td>
            <td>{{orderInfo.betaTechnologyBill.dimensionTemplate}}</td>
            <td>车位价格</td>
            <td>{{orderInfo.betaTechnologyBill.parkingPlacePrice}}</td>
          </tr> -->
          <tr height="40px" align="center" class="scan-tr">
            <td rowspan="5">
              <img
                v-if="orderInfo.betaTechnologyBill.frontImage"
                :src="orderInfo.betaTechnologyBill.frontImage"
                alt="商品图"
              />
            </td>
            <td>客户名称</td>
            <td>{{ orderInfo.betaTechnologyBill.customerName }}</td>
            <td>设计师</td>
            <td>{{ orderInfo.betaTechnologyBill.designer }}</td>
          </tr>
          <tr height="40px" align="center" class="scan-tr">
            <td>设计日期</td>
            <td>{{ orderInfo.betaTechnologyBill.designDate }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr height="40px" align="center" class="scan-tr">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr height="40px" align="center" class="scan-tr">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <table class="scan-type size-detail-border" width="80%" cellspacing="0">
          <tr
            height="40px"
            align="center"
            v-for="(item, index) in productList"
            :key="index"
          >
            <td class="scan-type-td">{{ item.measuringParts }}</td>
            <!-- <td class="scan-type-td">{{ item.measurementMethod }}</td> -->
            <td
              class="scan-type-td"
              :key="index"
              v-for="(sizeDetailStr, index) in item.sizeDetail"
            >
              {{ sizeDetailStr }}
            </td>
            <td class="scan-type-td">{{ item.tolerance }}</td>
            <td class="scan-type-td">{{ item.skipCode }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import printJS from 'print-js'
import {
  getSearchPost,
  savePrintingThermalPaper,
  scanGoodsRequest,
} from '../request/search'
import fileDownload from 'js-file-download'
const baseUrl = `http://dev.woman.engraph.ciker.link:42000`
const onlineUrl = 'http://124.71.192.201:42000'

export default {
  data() {
    return {
      showQueryResult: null,
      value: '',
      orderNo: '',
      orderInfo: null,
      singlePieceCode: '',
      productList: [],
    }
  },
  methods: {
    onSubmit() {
      if(this.value && this.value.indexOf('DJBT') !== -1) {
        this.getData()
        this.scanGoodsSinglePieceCode()
      } else {
        this.getData()
      }
    },
    //点击回车，进行搜索
    searchEnterFun (e){
      var keyCode = window.event ? e.keyCode : e.which;
      console.log("回车搜索", keyCode, e);
      if (keyCode == 13) {
        this.onSubmit()
      }
    },
    thermalPrintEvent() {
      let host = baseUrl
      if (location.host === 'ship.betatechgroup.com') {
        host = onlineUrl
      }
      let url =
        host +
        `/provider/betaProductTechnology/savePrintingThermalPaper?orderId=${this.orderInfo.orderId}&orderNo=${this.orderInfo.orderNo}&orderSource=${this.orderInfo.orderSource}`
      window.open(url)
    },
    orderQuantitySum(sum, total) {
      let calssStr = ''
      let str = sum + '/' + total
      if (sum >= total) {
        calssStr = ''
      } else {
        calssStr = ''
      }
      return str
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    setHtmlPrint() {
      // // 1. 设置要打印的区域 div的className
      // let newstr = document.getElementsByClassName('print-info')[0].innerHTML;
      // // 2. 复制给body，并执行window.print打印功能
      // document.body.innerHTML = newstr;
      // // 3. 打印
      // window.print();
      // window.location.reload();
    },
    getData() {
      this.orderInfo = null
      this.showQueryResult = '加载订单信息中......'
      getSearchPost(this.value).then(res => {
        this.productList = []
        if (res != null) {
          this.showQueryResult = !res.success ? res.msg : null
          if (res.success) {
            const data = res.data
            this.orderInfo = data
            this.productList = data.betaTechnologyBill.productSizeDetails
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        }
      }).catch((err) => {
        this.$message.error(`Error: ${err.status}\nInfo: ${err.statusText}`);
      })
    },
    scanGoodsSinglePieceCode() {
      scanGoodsRequest(this.value).then(res => {
        const data = res.data
        if (res.success) {
          this.$message({
            message: `计件成功！${data.productSize} + 1`,
            type: 'success',
            duration: 1000
          });
          if (this.orderInfo != null) {
            var tempData = this.orderInfo.goodsInfo.find(
              item => item.productSize == data.productSize,
            )
            tempData.scannedGoodsItemNum = data.scanTotalNum
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      }).catch((err) => {
        this.$message.error(`Error: ${err.status}\nInfo: ${err.statusText}`);
      })
    },
  },
  mounted() {
    var url = window.location.href
    var loc = url.substring(url.lastIndexOf('=') + 1, url.length)
    if (url.indexOf('=') !== -1) {
      this.value = loc
      this.getData()
    }
  }
};
</script>
<style lang="scss" scoped>
.scan {
  width: 100%;
  height: 100%;
  padding: 50px 0 0;
  box-sizing: border-box;
}

.demo-form-inline {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  padding-left: 10%;
  box-sizing: border-box;
}

.el-form-item {
  width: 50%;
  margin: 0;
  /deep/ .el-form-item__content {
    width: 100%;
    .el-input {
      width: 100%;
    }
  }
}
.box {
  width: 100%;
}
.scan-center {
  width: 100%;
  // height: 300px;
  display: flex;
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  /* align-items: center; */
}

.box-card {
  width: 48%;
  // height: 230px;
}

.item {
  height: 30px;
  line-height: 30px;
}

.card-item-left {
  display: flex;
  align-items: center;
  justify-content: space-around;
  b {
    font-weight: 400;
    // margin-right: 4px;
  }
}

.item h3 {
  height: 40px;
  line-height: 40px;
  margin: 0;
}
.scan-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scan-info {
  width: 80%;
  tr {
    height: 40px;
    td {
      width: 20%;
      img {
        width: 100%;
        height: 200px;
      }
    }
  }
}
.scan-type {
  width: 80%;
  tr {
    height: 40px;
    display: flex;
    td {
      flex: 1;
      line-height: 38px;
    }
  }
}
.size-detail-border {
  border-top: 0;
  border-bottom: 2px solid #000000;
  border-left: 2px solid #000000;
  border-right: 2px solid #000000;
  border-collapse: collapse;
}
.size-detail-border td,
.size-detail-border th {
  border-top: 0;
  border-bottom: 2px solid #000000;
  border-left: 0;
  border-right: 2px solid #000000;
  border-collapse: collapse;
}
</style>
