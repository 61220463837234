<!--
 * @Author: your name
 * @Date: 2021-08-11 14:25:58
 * @LastEditTime: 2021-08-15 18:46:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /new-order-h5/src/App.vue
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created () {
    document.title = '订单明细'
  },
}
</script>
<style>
</style>
