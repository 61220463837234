/*
 * @Author: your name
 * @Date: 2021-05-12 16:50:18
 * @LastEditTime: 2021-08-12 19:29:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /address-library/src/contants/api/index.js
 */
import govCloud from "./govCloud.js";

export default {
  /**
   * ====================
   * 接口
   * ====================
   */
  ...govCloud
};