/*
 * @Author: your name
 * @Date: 2021-08-15 16:14:14
 * @LastEditTime: 2021-08-15 21:53:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /new-order-h5/src/request/search.js
 */
import service from "@/utils/axios";
import API from "../contants/api"

// 订单-贝塔工艺单接口
export function getSearchPost(orderNo) {
  return service({
    url: `/api/provider/betaProductTechnologyInfo`,
    method: "get",
    params: {
      orderNo
    }
  })
}

// 订单-打印热敏纸接口
export function savePrintingThermalPaper(orderId,orderNo,orderSource) {
  return service({
    url: `/api/provider/betaProductTechnology/savePrintingThermalPaper`,
    method: "get",
    params: {
      orderId,
      orderNo,
      orderSource
    }
  }, {
    responseType: "blob"
  })
}

// 订单-扫描商品接口
export function scanGoodsRequest(singlePieceCode) {
  return service({
    url: `/api/provider/betaProductTechnology/scanGoods`,
    method: "post",
    params: {
      singlePieceCode
    }
  })
}
