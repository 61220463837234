/*
 * @Author: liguodong
 * @Date: 2021-05-12 16:50:40
 * @LastEditTime: 2021-08-12 19:51:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /address-library/src/contants/api/govCloud.js
 */
export default {
  SEARCH: `/provider/betaProductTechnologyInfo`, //地址检索接口
};
